<template>
  <div class="container-fluid">
    <Header></Header>
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-AccountSetup">
          <div class="row paj-custom-row">
            <div class="col-12 custom-wrapper-dashboard">
              <div class="d-flex align-items-center flex-wrap mr-2 text-title">
                <img
                  src="@/assets/images/accountsetup/setting.svg"
                  alt=""
                  class="mr-2"
                />
                {{ lbl['main-menu-setting'] }}
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-content-tab">
              <div class="d-flex wrapper-select-header">
                <div
                  class="select-header cursor-pointer"
                  :class="{ active: false }"
                  @click="goto('AccountSetting')"
                >
                  {{ lbl['account-setup-account-setting'] }}
                </div>
                <div
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: false }"
                  @click="goto('BranchSetting')"
                >
                  {{ lbl['account-setup-hq-and-branch'] }}
                </div>
                <div
                  v-if="isUseFacebook && loginTypeList.includes('Facebook')"
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: true }"
                >
                  {{ lbl['link-setup-tab-connect-facebook'] }}
                </div>
                <div
                  class="select-header select-hd-right cursor-pointer"
                  :class="{ active: false }"
                  @click="gotoConnectSetting"
                >
                  {{ lbl['link-setup-tab-connect'] }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-5">
            <div v-if="loginType">
              <div
                v-if="loginTypeList.includes('Facebook')"
                class="wrap-linkSetup"
              >
                <div class="row">
                  <div class="col-12 page-header pl-4">
                    {{ lbl['connect-facbook-header'] }}
                    <span class="sub-header ml-1"
                      >({{ lbl['account-setup-optional'] }})</span
                    >
                  </div>

                  <a-form class="col-12">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                        <div class="row">
                          <div
                            class="
                              col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                            "
                          >
                            <div class="row pt-3">
                              <div class="col-md-12 pl-4">
                                <div class="select-link mb-3">
                                  {{ lbl['please-enter-text'] }}
                                </div>
                                <div class="input-wrapper">
                                  <div class="title">
                                    <div class="number">
                                      {{ '1' }}
                                    </div>
                                    <div class="input-label">
                                      {{ lbl['setup-header-appid'] }}
                                    </div>
                                  </div>
                                  <a-input
                                    id="facebook-app-id"
                                    v-model="facebookAppId"
                                    :placeholder="lbl['required-text']"
                                    class="facebook-app-id-input"
                                  >
                                  </a-input>
                                  <a-button
                                    v-if="!isConnected"
                                    class="connect-button"
                                    :disabled="!facebookAppId"
                                    @click="connectFaceBook()"
                                  >
                                    {{
                                      lbl[
                                        'preview-setting-account-line-settup-not-connect'
                                      ]
                                    }}
                                  </a-button>
                                  <a-button
                                    v-if="isConnected"
                                    class="cancal-connect-button"
                                    :disabled="!facebookAppId"
                                    @click="clickUnConnectFaceBook()"
                                  >
                                    {{
                                      lbl[
                                        'preview-setting-account-line-settup-cancel-connect'
                                      ]
                                    }}
                                  </a-button>
                                  <div
                                    v-if="isConnected"
                                    class="connect-success"
                                  >
                                    <img
                                      src="@/assets/images/checked.svg"
                                      alt=""
                                      class="checked"
                                    />

                                    <span>
                                      {{
                                        lbl[
                                          'preview-setting-account-line-settup-connect'
                                        ]
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src="@/assets/images/how_to_connect_facebook.png"
                                  alt="how to connect facebook"
                                  class="image-guideline"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import Header from '@/components/Layout/Header'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import Locale from '@/helper/locale.js'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'

export default {
  name: 'ConnectFacebook',
  mixins: [Mixin],
  components: {
    Header,
    AlertModal,
    ConfirmModal,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      loginType: null,
      isPhilipines: false,
      facebookAppId: null,
      isUseFacebook: false,
      isConnected: false,
      loginTypeList: [],
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'connent-setting',
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'setting_account_page',
      'setting_account_facebook_connect',
      'view_facebook_connect',
      'on view',
    )
    this.isPhilipines = Vue.bzbsConfig.businessRule.webFor.PH
    this.isUseFacebook = Vue.bzbsConfig.businessRule.open.facebook
    this.init()
  },
  mounted() {
    this.handleFooter(true)
  },
  methods: {
    init() {
      Promise.all([this.getCRMPlusProfile()])
    },
    config: function () {
      return Vue.bzbsConfig
    },
    app_id: function () {
      return Cache.get('CRMUser').AppId
    },
    gotoConnectSetting() {
      Account.bzbsAnalyticTracking(
        'connect_facebook_page',
        'setting_account',
        'click_setting_account_line_connect_tab',
        'on click',
      )
      this.$router.push({ name: 'ConnectSetting' })
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    arrList(string) {
      var list = string.split(',')
      this.loginTypeList = list
    },
    genDataProfile(data) {
      this.loginType = data.LoginType ? data.LoginType.toLowerCase() : null
      this.arrList(data.LoginType)
      this.appId = this.app_id() ? this.app_id() : data.AppId
      this.facebookAppId = data.FacebookAppId
      this.isConnected = data.FacebookAppId ? true : false
    },
    connectFaceBook() {
      console.log('facebookAppId ::', this.facebookAppId)
      var param = {
        facebookAppId: this.facebookAppId,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.postConnectFacebook(param)
          .then(res => {
            console.log('postConnectFacebook res:: ', res.data)
            if (res.data.success) {
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                null,
                'success',
                true,
              )
              this.isConnected = true
            } else {
              this.alertModalAction(
                this.lbl['alert-box-error-header'],
                res.data.message,
                'error',
                true,
              )
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('postConnectFacebook error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    clickUnConnectFaceBook() {
      this.confirmModalAction(
        this.lbl['confirm-unconnect-facebook'],
        '',
        'warning',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.unConnectFaceBook()
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
        },
      )
    },
    unConnectFaceBook() {
      console.log('unConnectFaceBook')
      return new Promise(resolve => {
        BzbsSettingsLine.unConnectFacebook()
          .then(res => {
            console.log('unConnectFacebook res:: ', res.data)
            if (res.data.success) {
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                null,
                'success',
                true,
              )
              this.facebookAppId = null
              this.isConnected = false
            } else {
              this.alertModalAction(
                this.lbl['alert-box-error-header'],
                res.data.message,
                'error',
                true,
              )
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('postConnectFacebook error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
  },
}
</script>

<style lang="scss" scope>
@import '../styles/accountsetup_main.scss';

.page-header {
  .sub-header {
    font-size: 14px;
    color: #bdbdbd;
    font-weight: normal;
  }
}

.input-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-flow: wrap;

  .title {
    display: flex;
    gap: 8px;
    align-items: center;

    .number {
      width: 32px;
      height: 32px;
      color: #ffffff;
      border-radius: 32px;
      border: 1px solid $primary;
      background-color: $primary;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-label {
      color: #616161;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .facebook-app-id-input {
    height: 40px;
    max-width: 250px;
  }
  .connect-button {
    background-color: #f9a601;
    padding: 8px 16px;
    border-radius: 6px;
    color: #ffffff;
  }
  .cancal-connect-button {
    background-color: $btn-danger;
    border-color: $btn-danger;
    color: white;
  }
  .connect-button[disabled] {
    background-color: #b0b0b0;
  }
}
.image-guideline {
  max-width: 900px;
  margin: 32px 0 16px;
}
.connect-success {
  color: $color-active;
  font-weight: bold;
}
.wrap-AccountSetup {
  .line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 16px auto;
  }
}
</style>
